import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseDevicesTable } from "src/components/Paradise/ParadiseDevicesTable"
import { useParadiseDevicesFilter } from "src/components/Paradise/useParadiseDevicesFilter"
import { useFetchParadiseDevices } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const LIMIT = 15

export function ParadiseUserDevices({ userId }: { userId: string }) {
  const { filter, sortedFilter, setFilter, offset, setOffset } =
    useParadiseDevicesFilter()

  const fetchedDevices = useFetchParadiseDevices({
    filter: {
      user_id: userId,
      limit: LIMIT,
      offset,
      ids: filter.devices_id || undefined,
      description: filter.devices_description || undefined,
      include_inactive: filter.devices_include_inactive || undefined,
      offline: filter.devices_offline ?? undefined,
      hardware_version: filter.devices_hardwareVersion ?? undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })
  const devices = fetchedDevices.data?.devices ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Devices" />

      <OverviewWrapper>
        <Overview>
          <MText variant="nano">Total devices</MText>
          <MText variant="subtitleS">
            {fetchedDevices.data?.paging?.total_count}
          </MText>
        </Overview>
      </OverviewWrapper>

      <ParadiseDevicesTable
        devices={devices}
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        filter={filter}
        setFilter={setFilter}
        sortedFilters={sortedFilter}
        totalCount={fetchedDevices.data?.paging?.total_count}
        isLoading={fetchedDevices.isPreviousData}
        error={{
          hasError: fetchedDevices.isError,
          title: fetchedDevices.error?.message,
        }}
        disableColumnHiding
        hiddenColumns={["owner"]}
      />
    </div>
  )
}

const OverviewWrapper = styled.div`
  display: flex;
  gap: ${spacing.S};
  margin-top: ${spacing.S};
`

const Overview = styled.div`
  padding: ${spacing.XS};
  background-color: ${mColors.neutral};
  border-radius: 0.5rem;
`
