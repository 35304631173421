import { useState } from "react"
import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { TParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { useFlags } from "src/hooks/useFlags"
import { IconButton } from "src/ui/Button/IconButton"
import { mColors } from "src/ui/colors"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import TrashIcon from "src/ui/icons/trash.svg"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

const notes = [
  {
    id: "1",
    author: "Viktor",
    created_at: "2024-01-01T12:00:00Z",
    note: "This is a note that is long and should be wrapped. Super louper duper long note just keeps on going ",
  },
  {
    id: "2",
    author: "Elias",
    created_at: "2024-01-02T12:00:00Z",
    note: "This is a 2nd note",
  },
  {
    id: "3",
    author: "John Doe",
    created_at: "2024-01-03T12:00:00Z",
    note: "This is a 3rd note",
  },
  {
    id: "4",
    author: "John Doe",
    created_at: "2024-01-03T12:00:00Z",
    note: "This is a 4th note",
  },
  {
    id: "5",
    author: "John Doe",
    created_at: "2024-01-03T12:00:00Z",
    note: "This is a 5th note",
  },
  {
    id: "6",
    author: "John Doe",
    created_at: "2024-01-03T12:00:00Z",
    note: "This is a 6th note",
  },
  {
    id: "7",
    author: "John Doe",
    created_at: "2024-01-03T12:00:00Z",
    note: "This is a 7th note",
  },
  {
    id: "8",
    author: "John Doe",
    created_at: "2024-01-03T12:00:00Z",
    note: "This is a 8th note",
  },
  {
    id: "9",
    author: "John Doe",
    created_at: "2024-01-03T12:00:00Z",
    note: "This is a 9th note",
  },
  {
    id: "10",
    author: "John Doe",
    created_at: "2024-01-03T12:00:00Z",
    note: "This is a 10th note",
  },
  {
    id: "11",
    author: "John Doe",
    created_at: "2024-01-03T12:00:00Z",
    note: "This is a 11th note",
  },
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ParadiseUserNotes({ user }: { user: TParadiseUser }) {
  const { paradiseUserNotes } = useFlags()
  const [createNoteOpen, setCreateNoteOpen] = useState(false)
  const [noteIdToDelete, setNoteIdToDelete] = useState<string | null>(null)

  if (!paradiseUserNotes) {
    return null
  }

  function deleteNote(_noteId: string) {
    setNoteIdToDelete(null)
    // TODO: WEB-000 delete note
  }

  function createNote(_content: string) {
    setCreateNoteOpen(false)
    // TODO: WEB-000 create note
  }

  const rows = notes.map((note) => {
    return (
      <>
        <NoteRow>
          <div>
            <NoteContent>{note.note}</NoteContent>
            <MText variant="nano" color="secondary">
              {note.author} (
              {formatDate({ date: note.created_at, clockType: "24" })})
            </MText>
          </div>
          <div>
            <IconButton
              Icon={TrashIcon}
              variant="minimal"
              color="destructive"
              onClick={() => {
                setNoteIdToDelete(note.id)
              }}
            />
          </div>
        </NoteRow>
      </>
    )
  })

  return (
    <>
      <Wrapper>
        <ParadiseDetailsSection
          title="Notes"
          buttonLabel="Add note"
          onButtonClick={() => {
            setCreateNoteOpen(true)
          }}
        />
        <TableWrapper>{rows}</TableWrapper>
      </Wrapper>
      <CreateNoteDialog
        onClose={() => {
          setCreateNoteOpen(false)
        }}
        onSave={(content) => createNote(content)}
        open={createNoteOpen}
      />
      {noteIdToDelete && (
        <DeleteNoteDialog
          noteId={noteIdToDelete}
          onClose={() => setNoteIdToDelete(null)}
          onDelete={deleteNote}
          open={noteIdToDelete !== null}
        />
      )}
    </>
  )
}

function CreateNoteDialog({
  onClose,
  onSave,
  open,
}: {
  onClose: () => void
  onSave: (content: string) => void
  open: boolean
}) {
  const [noteContent, setNoteContent] = useState("")
  return (
    <>
      <ConfirmDialog
        open={open}
        onClose={onClose}
        title="Create note"
        onConfirm={() => onSave(noteContent)}
      >
        <MTextField
          value={noteContent}
          onChange={(value) => setNoteContent(value)}
          placeholder="Note content"
        />
      </ConfirmDialog>
    </>
  )
}

function DeleteNoteDialog({
  noteId,
  onClose,
  onDelete,
  open,
}: {
  noteId: string
  onClose: () => void
  onDelete: (noteId: string) => void
  open: boolean
}) {
  return (
    <ConfirmDialog
      title={"Are you sure you want to delete this note?"}
      onClose={onClose}
      confirmButtonProps={{
        color: "destructive",
      }}
      onConfirm={() => onDelete(noteId)}
      loading={false}
      open={open}
    >
      <div>
        <p>{`This action is irreversible.`}</p>
      </div>
    </ConfirmDialog>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
`

const TableWrapper = styled.div`
  max-height: 800px;
  overflow-y: auto;
`

const NoteRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: ${spacing.M};
  border-bottom: 1px solid ${mColors.divider};
  padding: ${spacing.M} 0;
`

const NoteContent = styled(MText)`
  white-space: wrap;
  width: auto;
`
